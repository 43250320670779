import React from "react";

export function Footer(props) {
  return (
    <>
    <div class="card text-center">
  <div class="card-header">
    Developer
  </div>
  <div class="card-body">
    <h5 class="card-title">Rohan Raju Kurekar</h5>
    <p class="card-text">Check out my LinkedIn profile for connecting.</p>
    <a href="https://www.linkedin.com/in/rohan-kurekar-abb058171/" class="btn btn-primary">LinkedIn</a>
  </div>
  <div class="card-footer text-muted">
  Technology enthusiast ready to work and learn technologies in any domain with good problem solving, adaptability, logical skills, and a quick learner.
  </div>
</div>
    </>
  );
}

//import logo from './logo.svg';
import { Fragment } from 'react';
import './App.css';
import { Footer } from './components/footer';
//import {app} from './firebaseConfig';
import { isLoggedInCheck, SignIn } from './login/google-sign';
import {Header} from './components/header';
import { BodyLoggedUser } from './components/body-logged-user';
import { CheckLoggedIn } from './components/checkLoggedIn';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  const auth = getAuth();
  var isLogged = false;
onAuthStateChanged(auth, (user) => {
  
  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/firebase.User
    const uid = user.uid;
    isLogged = true;
    console.log("logged in successully"+uid);
    // ...
  } else {
    // User is signed out
    // ...
    isLogged = false;
  }
});
  //const isLogged = isLoggedInCheck();
  console.log("**"+isLogged);
  
  return (
    /*
    <Fragment>
    <Header/>
   <CheckLoggedIn isLoggedIn={isLogged}/>

    <div className="App" >
      Welcome
    </div>
    <Footer/>
    </Fragment>
*/
    <Router>
      <Routes>
        <Route exact path="/" element={<CheckLoggedIn/>}></Route>
      </Routes>
    </Router>
  );

}

export default App;

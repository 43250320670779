// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBBGFL5k7Z7bJnKckgVAp0PJWRnCABq8tg",
  authDomain: "pinaka-23c82.firebaseapp.com",
  projectId: "pinaka-23c82",
  storageBucket: "pinaka-23c82.appspot.com",
  messagingSenderId: "163398196446",
  appId: "1:163398196446:web:433dca44324fafa49eb64f",
  measurementId: "G-QRK31150W1"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
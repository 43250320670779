import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import React, { Fragment } from "react";
import { app } from "../firebaseConfig";
import {  getRedirectResult } from "firebase/auth";
export function SignIn(props) {

    function SignInFirebase(){
        const auth = getAuth();
    const provider = new GoogleAuthProvider();
signInWithPopup(auth, provider)
  .then((result) => {
    // This gives you a Google Access Token. You can use it to access the Google API.
    const credential = GoogleAuthProvider.credentialFromResult(result);
    const token = credential.accessToken;
    // The signed-in user info.
    const user = result.user;
    console.log("Successful"+token+user);
    return (<Fragment>
        you are logged in
        </Fragment>
      );
    // ...
  }).catch((error) => {
    // Handle Errors here.
    const errorCode = error.code;
    const errorMessage = error.message;
    // The email of the user's account used.
    const email = error.customData.email;
    // The AuthCredential type that was used.
    const credential = GoogleAuthProvider.credentialFromError(error);
    console.log(errorCode+errorMessage+email+credential)
    // ...
  });
    }
    

  return (
    <button class="btn btn-outline-success" onClick={SignInFirebase}> Google Sign In
   </button>
  );
}

export function isLoggedInCheck(){

  const auth = getAuth();
getRedirectResult(auth)
  .then((result) => {
    // This gives you a Google Access Token. You can use it to access Google APIs.
    const credential = GoogleAuthProvider.credentialFromResult(result);
    const token = credential.accessToken;

    // The signed-in user info.
    const user = result.user;
    
  }).catch((error) => {
    // Handle Errors here.
    const errorCode = error.code;
    const errorMessage = error.message;
    // The email of the user's account used.
    const email = error.customData.email;
    // The AuthCredential type that was used.
    const credential = GoogleAuthProvider.credentialFromError(error);
    return false;
    // ...
  });

  return true;

}

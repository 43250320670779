import React from "react";
import { SignIn } from "../login/google-sign";

export function Header(props) {
  return (
    <div className="Header">
      <nav class="navbar navbar-expand-lg bg-light">
  <div class="container-fluid">
    
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        
        <li class="nav-item" class="fs-4">
        Krisvara
        </li>
      </ul>
      <form class="d-flex" role="search">
        <SignIn />
        &nbsp;
        
        <a href="https://www.linkedin.com/in/rohan-kurekar-abb058171/" class="btn btn-info">LinkedIn</a>
      </form>
    </div>
  </div>
</nav>


      
    </div>
  );
}


import React, { Fragment } from "react";
import { app } from "../firebaseConfig";
import { getAuth, getRedirectResult, GoogleAuthProvider } from "firebase/auth";
import { SignIn } from "../login/google-sign";
export function BodyLoggedUser(props) {
    
  return (
    <>
    <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src={process.env.PUBLIC_URL + '/image1.jpg'} class="d-block w-100" alt="..."></img>
      <div class="carousel-caption d-none d-md-block">
        <h5>Welcome to Krisvara</h5>
        <p>Your one stop solution for learning programming</p>
      </div>
    </div>
    <div class="carousel-item">
      <img src={process.env.PUBLIC_URL + '/image2.jpeg'} class="d-block w-100" alt="..."></img>
      <div class="carousel-caption d-none d-md-block">
        <h5>Data Structure and Alogrithm</h5>
        <p>We cover all the topics from basics to high level</p>
      </div>
    </div>
    <div class="carousel-item">
      <img src={process.env.PUBLIC_URL + '/image3.jpg'} class="d-block w-100" alt="..."></img>
      
      <div class="carousel-caption d-none d-md-block">
        <h5>This site is built using react</h5>
        <p>Still in progress</p>
      </div>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
    </>
    
  );
  
  
}

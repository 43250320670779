import { getAuth, onAuthStateChanged } from "firebase/auth";
import React from "react";
import { BodyLoggedUser } from "./body-logged-user";
import { SignIn } from "../login/google-sign";
import { Header } from "./header";
import { Footer } from "./footer";
export function CheckLoggedIn(props) {
  const auth = getAuth();
  var isLogged = false;
onAuthStateChanged(auth, (user) => {
  
  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/firebase.User
    const uid = user.uid;
    isLogged = true;
    console.log("logged in successully"+uid);
    // ...
  } else {
    // User is signed out
    // ...
    isLogged = false;
  }
});
  //const isLogged = isLoggedInCheck();
  console.log("**"+isLogged);
   // const isLoggedIn = props.isLoggedIn;
  if (isLogged) {
    return <BodyLoggedUser />;
  }else{
    return <>
    <Header/>
    <BodyLoggedUser/>
    <Footer/>
    </>;
  }
  
  
}
